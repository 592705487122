<template>
    <transition
        name="slide"
        @enter="enter"
        @leave="$emit('leave')"
        @after-enter="$emit('after-enter')"
        @after-leave="afterLeave"
    >
        <aside v-if="show" class="kk-aside">
            <div class="left-bar">
                <div class="action">
                    <button class="close-btn" @click="close">
                        <fa-icon :icon="['fal', 'arrow-circle-right']" />
                    </button>
                </div>
                <div v-if="tabs" class="tabs">
                    <ul v-for="tab in tabs" :key="tab.name">
                        <li :class="{ selected: tab.selected }" @click="changeTab(tab.ref)">
                            <p>{{ tab.name }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content-wrapper">
                <header v-if="tabs.length === 0" :class="{ sticky: stickyheader }" class="aside-header">
                    <slot name="header" />
                </header>
                <div class="aside-content">
                    <section v-if="tabs.length === 0">
                        <slot name="content" />
                    </section>
                    <section v-for="tab in tabs" v-else :key="tab.name">
                        <slot v-if="getSelectedTab(tab.ref)" :name="tab.ref" />
                    </section>
                </div>
            </div>
        </aside>
    </transition>
</template>

<script>

import ClickOutside from 'click-outside-vue3';

export default {
    emits: ['leave', 'after-enter', 'close', 'enter', 'after-leave'],
    name: 'kk-aside',

    directives: {
        ClickOutside: ClickOutside.directive,
    },

    props: {
        show: {
            type: Boolean,
            default: false,
        },
        stickyheader: {
            type: Boolean,
            default: false,
        },
        tabs: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        changeTab(toTab) {
            this.tabs.map((tab) => {
                tab.selected = tab.ref === toTab;

                return tab;
            });
        },
        getSelectedTab(tab) {
            return this.tabs.find(x => x.ref === tab).selected;
        },
        close() {
            this.$emit('close');
        },
        enter() {
            this.$emit('enter');
            document.body.classList.add('hidescrollbar');
        },
        afterLeave() {
            this.$emit('after-leave');
            document.body.classList.remove('hidescrollbar');
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-leave-active,
.slide-enter-active {
    transition: 700ms;
}
.slide-enter-from {
    transform: translate(100%, 0);
}
.slide-leave-to {
    transform: translate(110%, 0);
}

.sticky {
    background: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
}
.kk-aside {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    min-width: 1000px;
    min-height: 600px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);

    .left-bar {
        position: sticky;
        top: 0;
        height: inherit;
        background-color: #0AAEF3;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);

        .tabs {
            padding: 30px 0;
            font-size: 14px;
            color: #fff;
            font-weight: 500;
            ul {
                margin: 0;
                padding: 0;
                text-indent: 0;
                list-style-type: none;
                li {
                    cursor: pointer;
                    padding: 1px 20px;
                    &.selected, &.selected:hover {
                        background-color: #44C1F6;
                    }
                    &:hover {
                        background-color: #36b7ee;
                    }
                    p {
                        margin: 10px 0;
                    }
                }
            }
        }
        .close-btn {
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        background-color: #026F9D;
        color: #fff;
        padding: 0;
        box-shadow: 0 3px 7px 0 rgba(0,0,0,0.15);
        font-size: 21px;
        svg, button {
            outline: none;
            background: none;
            border: none;
            border-bottom-left-radius: 0;
        }
    }

    .content-wrapper {
        width: 100%;
        .aside-header {
            display: flex;
            align-items: flex-end;
            min-height: 60px;
            padding: 0 20px 15px;
            border-bottom: 1px solid #D6D5D5;
        }
    }
}
</style>

<style lang="scss">
//Global scrollbar style for animation
.hidescrollbar::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
    height: 0;
}
</style>
